// import React, { Fragment, useState, useEffect } from "react";
// import Navbar from "../Navbar";
// import Footer from "../Footer";
// import { useTranslation } from 'react-i18next';
// import travelimg from '../tr.png';
// import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
// import Cookies from 'js-cookie';
// import { event } from "jquery";
// const { config } = require('../api/api');

// function SearchPage() {
//   const base_url = config.baseURL;

//   const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';


//   const { t } = useTranslation();
//   const [vendorKey, setVendorkey] = useState('');

//   const [formData, setFormData] = useState({
//     insurancetype: '',
//   });
//   const [loading, setLoading] = useState(false);

//   const [searchvalue, setSearchValue] = useState("");
//   const [contract_id, setcontractsID] = useState('');
//   const [airport, setAirPorts] = useState([]);
//   const [hoveredIndex, setHoveredIndex] = useState(null);
//   const [locationvalue, setLocationvalue] = useState(null);

//   const handleMouseEnter = (index) => {
//     setHoveredIndex(index);
//   };

//   const handleMouseLeave = () => {
//     setHoveredIndex(null);
//   };
//   useEffect(() => {

//     const postvData = { organization_name: "Superjet" };
//     debugger

//     if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {

//       debugger
//       fetch(base_url + '/create_organization', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(postvData),
//       })
//         .then(response => response.json())
//         .then(data => {
//           setVendorkey(data.result[0].vendor_key);
//         })
//         .catch(error => {
//           console.error('Error posting data:', error);
//         });


//     }
//   }, [vendorKey]);




//   const postData = {
//     "limit": 20,
//     "offset": 0
//   };


//   useEffect(() => {
//     debugger

//     if (vendorKey !== '') {
//       fetch(base_url + '/get_contracts', {
//         method: 'POST',
//         headers: {
//           'Authorization': `Bearer ${vendorKey}`,
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(postData),

//       })
//         .then(response => response.json())
//         .then(data => {

//           setcontractsID(data.result.results[0].id);

//         })
//         .catch(error => {
//           console.error('Error posting data:', error);
//         });

//     }
//   }, [vendorKey]);


//   const handleSearchChange = (event) => {

//     var value = event.target.value
//     setSearchValue(value);
//     debugger
//     const postData1 = {
//       "limit": 20,
//       "offset": 0,
//       "contract_id": contract_id,
//       "query": value
//     };

//     if (value.length >= 3) {
//       if (vendorKey !== '' && value.length >= 3) {
//         fetch(base_url + '/get_airport_lounges', {
//           method: 'POST',
//           headers: {
//             'Authorization': `Bearer ${vendorKey}`,
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify(postData1),

//         })
//           .then(response => response.json())
//           .then(data => {

//             setAirPorts(data.result.results);

//           })
//           .catch(error => {
//             console.error('Error posting data:', error);
//           });

//       }
//     } else {
//       setAirPorts([]);
//     }
//   };

//   const handleSubmit = (iataValue, locationdetails , tittle) => {
//     debugger
//     var postData2 = {
//       "contract_id": contract_id,
//       "query": iataValue,
//       "location": locationdetails,
//       "searchtitle": tittle
//     }
//     sessionStorage.setItem("hotaldetails", JSON.stringify(postData2));
//     window.location.href = '/lounge-list'
//   };








//   return (
//     <Fragment>

//       <div className="header_section1">

//         <Navbar />


//         <div className="container-fluid bg-breadcrumb">
//           <div className="container text-center py-5">
//             <h3 className="abt-txt my-5">{(lnstr === 'en') ? 'Search for Lounges' : 'البحث عن الصالات\n'}</h3>
//           </div>
//         </div>



//       </div>


//       <div className="features_section">
//         <div className="container">

//           <div className="row">

//             <Col md={2}></Col>

//             <Col md={8} className="myforms my-5">

//               <h3>Search for lounges</h3>
//               <div style={{ position: 'relative' }}><i className="fas fa-search" style={{
//                 position: 'absolute',
//                 left: '10px',
//                 top: '50%',
//                 transform: 'translateY(-50%)',
//                 fontSize: '18px',
//                 color: '#ccc',
//               }}></i>
//                 <input type="text" className="form-control" style={{
//                   paddingLeft: '30px',
//                   fontSize: '16px',
//                   border: '1px solid #ccc',
//                   borderRadius: '4px',
//                 }} onChange={handlefnameChange} />
//               </div>


//               {/* {searchvalue && <div>
//                 <h1>
//                     {searchvalue}</h1></div>} */}

//               <div className="p-1">


//                     {airport.map((air, index) => (
//                       <div className="d-flex justify-content-between my-3 colmds-4 card m-0" onMouseEnter={() => handleMouseEnter(index)}
//                       onMouseLeave={handleMouseLeave} style={{
//                         backgroundColor: hoveredIndex === index ? '#e6e6e6' : 'transparent',
//                         cursor: 'pointer',
//                         transition: 'background-color 0.3s ease',
//                       }}>
//                       <div className="d-flex justify-content-between my-1"  onClick={() => handleSubmit(air.iata , air.location , air.title)} key={index}>
//                         <div>
//                           <h3>{air.title}</h3>
//                         </div>
//                         <div>
//                           <p style={{ textAlign: 'right' }}>
//                             {air.iata}
//                             <p>
//                               {air.city.title}
//                             </p>
//                           </p>

//                         </div>
//                       </div>
//                       </div>
//                     ))}


//                 <div>
//                 </div>
//               </div>


//               {/* <div class="row row-cols-1 row-cols-md-2 g-4">
//                 <div class="col">
//                   <div class="card p-2">
//                     <img src="https://cdn1.matadornetwork.com/blogs/1/2019/09/TWA-airport-hotel-room-1200x835.jpg" class="card-img-top" alt="..." />
//                     <div class="card-body">
//                       <h5 class="card-title">Card title</h5>
//                       <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
//                     </div>
//                   </div>
//                 </div>
//                 <div class="col">
//                   <div class="card p-2">
//                     <img src="https://cdn1.matadornetwork.com/blogs/1/2019/09/TWA-airport-hotel-room-1200x835.jpg" class="card-img-top" alt="..." />
//                     <div class="card-body">
//                       <div class="card-title justify-content-between">
//                         <div>
//                           <h5><strong>Travel Club Lounge B</strong></h5>
//                         </div>
//                         <div>
//                           <h5><strong>24 USD</strong></h5>
//                         </div>
//                       </div>
//                       <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
//                     </div>
//                   </div>
//                 </div> */}
//                 {/* <div class="col">
//     <div class="card">
//       <img src="..." class="card-img-top" alt="..."/>
//       <div class="card-body">
//         <h5 class="card-title">Card title</h5>
//         <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content.</p>
//       </div>
//     </div>
//   </div> */}
//                 {/* <div class="col">
//     <div class="card">
//       <img src="..." class="card-img-top" alt="..."/>
//       <div class="card-body">
//         <h5 class="card-title">Card title</h5>
//         <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
//       </div>
//     </div>
//   </div> */}
//               {/* </div> */}






//             </Col>

//             <Col md={2}></Col>

//           </div>

//         </div>
//       </div>

//       <Footer />

//       {loading && (
//         <div className="overlay">
//           <div className="spinner"></div>
//         </div>
//       )}

//     </Fragment>
//   );
// }

// export default SearchPage;




import React, { Fragment, useState, useEffect, useRef } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { useTranslation } from 'react-i18next';
import travelimg from '../tr.png';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';
import { event } from "jquery";
import $ from 'jquery';
import loungesimg from './lounges2.jpg';
const { config } = require('../api/api');

function SearchPage() {
  const base_url = config.baseURL;

  const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';


  const { t } = useTranslation();
  const [vendorKey, setVendorkey] = useState('');

  const [formData, setFormData] = useState({
    insurancetype: '',
  });
  const [loading, setLoading] = useState(false);

  const [contract_id, setcontractsID] = useState('');
  const [countryname, setCountryName] = useState([]);
  const [airport, setAirPorts] = useState([]);
  const selectOpt1 = useRef(null);
  const selectOpt2 = useRef(null);
  const [selectcountryname, setSelectCountryname] = useState(null);
  const [selectairportname, setSelectAirPortName] = useState(null);
  const [airport_id, setAirPortId] = useState(null);
  const [error, setErrorvalue] = useState('');
  const [airport_location , setAirPortLocation] = useState(null);

  useEffect(() => {

    const postvData = { organization_name: "Superjet" };
    debugger

    if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {

      debugger
      fetch(base_url + '/create_organization', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postvData),
      })
        .then(response => response.json())
        .then(data => {
          setVendorkey(data.result[0].vendor_key);
        })
        .catch(error => {
          console.error('Error posting data:', error);
        });


    }
  }, [vendorKey]);



  useEffect(() => {

    const fetchNationality = async () => {
      try {


        const response = await fetch(base_url + '/get_lounges_country', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${vendorKey}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();

        setCountryName(data.result);
      } catch (error) {
        console.error('Error fetching occupations:', error);
      }
    };

    fetchNationality();
  },
    [vendorKey]);

  useEffect(() => {
    //debugger
    $(selectOpt2.current).select2();
    $(selectOpt2.current).on('select2:select', (e) => {
      const selectedValue = e.params.data.text;
      setSelectCountryname(selectedValue);

      var data = {
        'country_name': selectedValue
      }

      if (vendorKey !== '' && selectedValue !== '') {
        fetch(base_url + '/get_lounges_airportname', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${vendorKey}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),

        })
          .then(response => response.json())
          .then(data => {

            setAirPorts(data.result);

          })
          .catch(error => {
            console.error('Error posting data:', error);
          });

      }

    });

    return () => {
      $(selectOpt2.current).select2('destroy');
    };
  }, [vendorKey, countryname]);


  useEffect(() => {
    $(selectOpt1.current).select2();
    $(selectOpt1.current).on('select2:select', (e) => {
      debugger
      const selectedValue = e.params.data.text;
      const selectairport_id = e.params.data.id;

      setSelectAirPortName(selectedValue);
      setAirPortId(selectairport_id);
    });

    // Clean up Select2 instance on unmount
    return () => {
      $(selectOpt1.current).select2('destroy');
    };
  }, [vendorKey, airport]);





  const postData = {
    "limit": 20,
    "offset": 0
  };


  useEffect(() => {
    debugger

    if (vendorKey !== '') {
      fetch(base_url + '/get_contracts', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${vendorKey}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),

      })
        .then(response => response.json())
        .then(data => {

          setcontractsID(data.result.results[0].id);

        })
        .catch(error => {
          console.error('Error posting data:', error);
        });

    }
  }, [vendorKey]);


  const handleSelectCountry = (name, value) => {

    // setFormData({ ...formData, [name]: value });
    debugger
    setSelectAirPortName(value)

    // console.log(formData);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    debugger
    if (selectcountryname === null || selectcountryname === 'Select Country') {
      alert('Please select a Country')
    } else if (selectairportname === null  ||  selectairportname === 'Select Airport') {
      alert('please select a Airport')
    } else {
      event.preventDefault();
      setLoading(true)
      const postData = {
        "limit": 20,
        "offset": 0,
        "contract_id": contract_id,
        "query": airport_id
      };
      if (vendorKey !== '') {

        fetch(base_url + '/get_airport_lounges', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${vendorKey}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postData),

        })
          .then(response => response.json())
          .then(data => {

            // setAirPorts(data.result.results[0].location);

            var locationvalue = data.result.results[0].location

            var data1={
              'location':locationvalue
            }

            sessionStorage.setItem('location',JSON.stringify(data1))

          })
          .catch(error => {
            console.error('Error posting data:', error);
          });

      fetch(base_url + '/get_contract_praducts_iata', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${vendorKey}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),

      })
        .then(response => response.json())
        .then(data => {

          // setHotelDetails(data.result.results);

          var airportvalue = data.result.results
          if (airportvalue.length > 0) {
            event.preventDefault();
            setLoading(true)
            var postData2 = {
              "contract_id": contract_id,
              "query": airport_id,
              // "location": locationvalue,
              "searchtitle": selectairportname
            }
            sessionStorage.setItem("hotaldetails", JSON.stringify(postData2));
            setLoading(false)
            window.location.href = '/lounge-list'
          } else {
            setErrorvalue('Lounges for not Available')
            setLoading(false)
          }

        })
        .catch(error => {
          console.error('Error posting data:', error);
          setLoading(false)
        });

    }


  }

};








return (

  <Fragment>
    {/* <div className="header_section1"> */}
    <Navbar />

    {/* <div className="container-fluid bg-breadcrumb">
          <div className="container text-center py-5">
            <h3 className="abt-txt my-5">{(lnstr === 'en') ? 'Search for Lounges' : 'البحث عن الصالات\n'}</h3>
          </div>
        </div>
      </div> */}
    <div className="features_section" style={{
      backgroundImage: `url(${loungesimg})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      minHeight: 'calc(120vh - 200px)',
    }}>
      <div className="container">
        <div className="row">
          <Col md={2}></Col>

          <Col md={8} className="myforms my-5">

            <div className=" container pt-5">

              <form style={{ backgroundColor: 'rgba(255, 255, 255, 0.6)', borderRadius: '2.5rem' }} className="container p-5" onSubmit={handleSubmit}>

                <div className="row justify-content-center">
                  <h2 className="text-center"><strong><u>E-Lounges</u></strong></h2>

                </div>

                {/* <br/> */}


                <div className="container">
                  <h3><strong>Country</strong></h3>

                  <select
                    ref={selectOpt2}
                    className="form-control"
                    style={{ borderRadius: '5px', height: '30px', width: '100%', maxWidth: '300px' }}
                    onChange={handleSelectCountry} required
                    value={selectcountryname}
                  >
                    <option value={'Select Country'} >Select Country</option>
                    {countryname.map((name) => (
                      <option key={name} value={name}>{name.country_name}</option>
                    ))}
                  </select>

                  <div className="styledata">

                  </div>

                </div>
                <div className="container pt-3">
                  <h3><strong>Airport</strong></h3>
                  <select
                    ref={selectOpt1}
                    className="form-control"
                    style={{ borderRadius: '5px', height: '30px', width: '100%', maxWidth: '300px' }}
                    required
                  // value={selectcountryname}
                  >
                    <option value={'Select Airport'}>Select Airport</option>
                    {airport.map((airname) => (
                      <option key={airname} value={airname.iata}>{airname.airport_name}</option>
                    ))}
                  </select>
                </div>

                {error && (
                  <div className="d-flex justify-content-center pt-3">
                    <h4 style={{ color: 'red' }}><strong>{error}</strong></h4>
                  </div>
                )}

                <div className="d-flex justify-content-center  pt-4">
                  <button className="btn btn-primary">Submit</button>
                </div>

              </form>

            </div>
            <h3></h3>
            {/* <div style={{ position: 'relative' }}>
                <i className="fas fa-search" style={{
                  position: 'absolute',
                  left: '10px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  fontSize: '18px',
                  color: '#ccc',
                }}></i>
                <input type="text" className="form-control" style={{
                  paddingLeft: '30px',
                  fontSize: '16px',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                }} onChange={handleSearchChange} />
              </div> */}

            {/* <div className="p-1">
                {airport.map((air, index) => (
                  <div className="d-flex justify-content-between my-3 colmds-4 card m-0" onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave} style={{
                      backgroundColor: hoveredIndex === index ? '#bfbfbf' : '#f2f2f2',
                      cursor: 'pointer',
                      transition: 'background-color 0.3s ease',
                    }}>
                    <div className="d-flex justify-content-between my-1" onClick={() => handleSubmit(air.iata, air.location, air.title)} key={index}>
                      <div>
                        <h3>{air.title}</h3>
                      </div>
                      <div>
                        <p style={{ textAlign: 'right' }}>
                          {air.iata}
                          <p>{air.city.title}</p>
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div> */}
          </Col>

          <Col md={2}></Col>

        </div>
      </div>
    </div >

    <Footer />

    {
      loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )
    }
  </Fragment >
);
}

export default SearchPage;
