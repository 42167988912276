import React, { Fragment, useState, useEffect, useRef } from "react";
import Navbar from "../../Navbar";
import Cookies from 'js-cookie';
import Footer from "../../Footer";
import { useTranslation } from 'react-i18next';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
const { config } = require('../../api/api');

function Tenantpagedetails(){
  const base_url = config.baseURL;

 
  
    const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';
    const [loading, setLoading] = useState(false);

    const { t } = useTranslation();

   const [addressError, setAddressError] = useState('');
  const [formData, setFormData] = useState([]);

  
  const typeBuildingRef=useRef(null);
  const floorNoRef=useRef(null);
  const addressRef=useRef(null);
  const [errors,setErrors]=useState(null);

  const [vendorKey, setVendorkey] = useState('');
  useEffect(() => {

      const postvData = { organization_name: "Superjet" };
  
      if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {
  
  
        fetch( base_url + '/create_organization', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postvData),
        })
          .then(response => response.json())
          .then(data => {
            setVendorkey(data.result[0].vendor_key);
          })
          .catch(error => {
            console.error('Error posting data:', error);
          });
  
  
      }
    }, [vendorKey]);

  const [dhofarData, setDhofarData] = useState(false);
  useEffect(() => {


    const storedDhofarString = Cookies.get('homeData'); //sessionStorage.getItem('userData')
    if (storedDhofarString !== undefined) {
      const storedDhofar = JSON.parse(storedDhofarString);
      setDhofarData(storedDhofar);
    }
    else {
      // alert("token expired");
      // window.location.href='/';
    }

  }, []);

  const postData = { token: dhofarData };
  useEffect(() => {

    if (vendorKey !== '') {
      fetch(base_url + '/home_details', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${vendorKey}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),

      })
        .then(response => response.json())
        .then(data => {
          setFormData(data.result.data);



        })
        .catch(error => {
          console.error('Error posting data:', error);
        });

    }
  }, [vendorKey]);





const handleSubmit = async (event) => {
  event.preventDefault();
debugger
  var data = {
    "type_of_building": buildingtype,
    "floor_no":floorno,
    "address":address
  }
    if(data.type_of_building===""||data.type_of_building===null||data.type_of_building===undefined){
      typeBuildingRef.current.focus();
      setErrors((data)=>({...data,typeOfBuildingErr:"Please enter valid details"}));
      return;
    }
    else if(data.floor_no===""||data.floor_no===null||data.floor_no===undefined){
      floorNoRef.current.focus();
      setErrors((data)=>({...data,floorNoErr:"Please enter valid details"}));
      return;
    }
    else if(data.address===""||data.address===null||data.address===undefined){
      addressRef.current.focus();
      setErrors((data)=>({...data,addressOfPropertyErr:"Please enter valid details"}));
      return;
    }

  sessionStorage.setItem("hometenantdetails" , JSON.stringify(data));

  setLoading(true);
    // Check if the selected option is 'Personal Accident'
      window.location.href ='/tenantphotograph';
}

const [agebuilding, setagebuildingValue] = useState('');

  const handleChangeage = (event) => {
    setagebuildingValue(event.target.value);
  };

  const [buildingtype, setBuildingType] = useState('')
  const handleChangeBuildingType = (e) => {

    // setFormData({ ...formData, [name]: value });
    setBuildingType(e.target.value)

    // console.log(formData);
  };

  const [floorno, setFloorNo] = useState('')
  const handleChangeFloorno = (e) => {
    setFloorNo(e.target.value);
  };

  const [address, setaAddressValue] = useState('');

  const handleChangeAddress = (event) => {
    if(event.target.value.trimStart()!==""||event.target.value.trimStart().length===0){
    setaAddressValue(event.target.value);
    }
  };

  useEffect(()=>{
     const getTenantDetails=sessionStorage.getItem("hometenantdetails");
     if(getTenantDetails!==undefined&& getTenantDetails!==null&& getTenantDetails!==""){
      const tenantDetails=JSON.parse(getTenantDetails);
      setaAddressValue(tenantDetails.address);
      setBuildingType(tenantDetails.type_of_building);
      setFloorNo(tenantDetails.floor_no);
     }
  },[])
  const handleAddressBlur = (e) => {
    const { value } = e.target;

    const addressPattern = /^[a-zA-Z\s,-]+$/
    if (value === '') {
      setAddressError('Please enter valid details');
    } 
    else if (!addressPattern.test(value)) {
      setAddressError('Please enter valid details '); 
    }
    else if (value.trimStart()==="") {
      setAddressError('Please enter valid details '); 
    }
    else {
      setAddressError('');
    }
  };
 
const handleSelectError=(e)=>{
    const name=e.target.name;

     if(name==="type_of_building"&&(buildingtype===""||buildingtype===null||buildingtype===undefined)){
      setErrors((data)=>({...data,typeOfBuildingErr:" Please enter valid details"}))
     }
     if(name==="type_of_building"&&(buildingtype!==""&&buildingtype!==null&&buildingtype!==undefined)){
      setErrors((data)=>({...data,typeOfBuildingErr:""}));
     }

     if(name==="floor_no" && (floorno===""||floorno===null||floorno===undefined)){
      setErrors((data)=>({...data,floorNoErr:"Please enter valid details"}));
     }
     if(name==="floor_no" && (floorno!==""&&floorno!==null&&floorno!==undefined)){
      setErrors((data)=>({...data,floorNoErr:""}));
     }
  }

    return(
        <Fragment>

      <div className="header_section1">
        <Navbar />
      <div className="container-fluid bg-breadcrumb">
      <div className="container text-center py-5">
        <h3 className="abt-txt my-5">{(lnstr === 'en') ? 'Home Insurance' : 'Home Insurance\n'}</h3>
      </div>
      </div>
      </div>


      <div className="features_section">
        <div className="container">

          <div className="row">

            <Col md={3}></Col>

            <Col md={6} className="myforms my-5">


            <div>
    <h1>Property Insurance Form</h1>
    <form>
      {formData?.map(item => (
        <div key={item.key}>
          
          <form onSubmit={" "}>

{item.type === "Select" && item.key === "type_of_building" && (
                          <>
                          <div className="input-group mb-2 mt-2 w-100">
                            <div className="input-group-prepend prepends"></div>
                            <select className="form-control"style={{width: '100%'}} 
                            ref={typeBuildingRef}
                              name={item.key} value={buildingtype} onChange={handleChangeBuildingType} onBlur={handleSelectError} onFocus={()=>{  setErrors((data)=>({...data,typeOfBuildingErr:""})); }}required>
                              <option value="" disabled selected>
                                {lnstr === "en" ? item.text_english : item.text_arabi}
                              </option>
                              {item.options.map((option, index) => (
                                <option key={index} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className={errors?.typeOfBuildingErr?"text-danger mb-2":"text-black mb-2"}>House/ Villa, Apartment/ Flat or Others.{errors?.typeOfBuildingErr}</div>
                          </>
                        )}

                        {item.type === "Select" && item.key === "floor_no" && (
                          <>
                          <div className="input-group mb-2 mt-2">
                            <div className="input-group-prepend prepends"></div>
                            <select className="form-control" name={item.key} 
                            ref={floorNoRef}
                            value={floorno} onChange={handleChangeFloorno} 
                            onBlur={handleSelectError} onFocus={()=>{  setErrors((data)=>({...data,floorNoErr:""})); }}required>
                              <option value="" disabled selected>
                                {lnstr === "en" ? item.text_english : item.text_arabi}
                              </option>
                              {item.options.map((option, index) => (
                                <option key={index} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="text-danger p-0 mb-2">{errors?.floorNoErr}</div>
                          </>
                        )}

 {item.type === "Text" && item.key === "address" && (
                        <>
                          <div className="input-group mb-2 mt-2">
                            <div className="input-group-prepend prepends"></div>
                            <textarea
                              placeholder={lnstr === "en" ? item.text_english : item.text_arabi}
                              name={item.key}
                              ref={addressRef}
                              className="form-control"
                              value={address}
                              onChange={handleChangeAddress}
                              onBlur={handleAddressBlur}
                              required
                            />
                          </div>
                          <div className="text-danger p-0 mb-2">{addressError}</div>
                         </>
                        )}

    </form>
        </div>
      ))}
      <button variant="secondary"  className="btn btn-primary rounded-pill text-white my-3 py-1 px-4" onClick={handleSubmit} >{(lnstr === 'en') ? 'Proceed' : 'يتابع'}</button>
    </form>
  </div>
             
          </Col>
          
            <Col md={3}></Col>
           
          </div>
          
        </div>
      </div>





      
        
       
       <Footer />
       {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
 
      )}

       </Fragment>
    )
}

export default Tenantpagedetails;