import React, { Fragment, useState, useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { useTranslation } from 'react-i18next';
import travelimg from '../tr.png';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';
const { config } = require('../api/api');


function LoungesPaymentPage() {
    const base_url = config.baseURL;

    const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';


    const { t } = useTranslation();

    const [formData, setFormData] = useState({
        insurancetype: '',
    });
    const [loading, setLoading] = useState(true);
    // const [selectedDateofBirth, setSelectedDateofBirth] = useState(null);
    const [selectedname, setSelectedName] = useState(null);
    const [selectedsurname, setSelectedSurName] = useState(null);
    const [selectedemail, setSelectedEmail] = useState(null);
    const [selectedmobilenumber, setSelectedMobileNumber] = useState(null);
    const [vendorKey, setVendorkey] = useState('');
    const [order_id , setOrderID] = useState('');
    const [fulldetailsvalue , setFullDetailsValue] = useState(null)
    const [randomString, setRandomString] = useState('');



    useEffect(() => {

        const postvData = { organization_name: "Superjet" };
        debugger

        if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {


            fetch(base_url + '/create_organization', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(postvData),
            })
                .then(response => response.json())
                .then(data => {
                    setVendorkey(data.result[0].vendor_key);
                })
                .catch(error => {
                    console.error('Error posting data:', error);
                });


        }
    }, [vendorKey]); // Dependency array


    const handleSubmit = async (event) => {
        event.preventDefault();
        debugger

        if (selectedname === null) {
            event.preventDefault();
            document.getElementsByName('name')[0].focus();
        } else if (selectedsurname === null) {
            event.preventDefault();
            document.getElementsByName('surname')[0].focus();
        }else if ( selectedemail == null){
            document.getElementsByName('email')[0].focus();
        }else if (selectedmobilenumber == null){
            document.getElementsByName('moblienumber')[0].focus();
        }
        else {
            setLoading(true)
            debugger
            const amount = fulldetailsvalue.total;
            const currency = fulldetailsvalue.currency;

            var personaldata = {
                "lounge_order_id":order_id,
                "amount_cents": amount,
                "currency": currency,
                "reference_id": randomString,
                "redirection_url": base_url+"/lounge_payment_log",
                "description": "lounges ",
                "first_name": selectedname,
                "last_name": selectedsurname,
                "phone_no": selectedmobilenumber,
                "email": selectedemail
            }

            debugger

            if (vendorKey) {
                fetch(base_url + '/create_lounge_payment', {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${vendorKey}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(personaldata),
                })
                    .then(response => response.json())
                    .then(data => {
                        const result = data?.result;
                        // alert(data?.message)
                        window.location.href = data?.result?.client_url
                        setLoading(false);
                    })
                    .catch(error => {
                        console.error('Error posting data:', error);
                        setLoading(false); 
                    });
            } else {
                console.error('Error: vendorKey is missing or invalid');
            }

            // sessionStorage.setItem("personalaccidentbasicinfo", JSON.stringify(data))


            // setLoading(true);

            event.preventDefault();
            // window.location.href = '/accident-cover';

        }

    };

    const generateRandomString = (length) => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
          const randomIndex = Math.floor(Math.random() * characters.length);
          result += characters.charAt(randomIndex);
        }
        return result;
      };

    useEffect(() => {
        debugger
        const personalvalue = sessionStorage.getItem('personaldetails');
        if (personalvalue != undefined) {
            const value = JSON.parse(personalvalue);

            setSelectedName(value.personaldetail.firstName);
            setSelectedSurName(value.personaldetail.lastName);
            setSelectedEmail(value.personaldetail.email);
            setOrderID(value.fulldetails.order_id);
            setFullDetailsValue(value.fulldetails);
            setRandomString(generateRandomString(10));
            setLoading(false);

        }

    }, []);



    const [buttonDisabled, setButtonDisabled] = useState(false);
    // const postData = { token: dhofarData };
    // const today = new Date().toISOString().split('T')[0];
    const today = new Date();
    const maxDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate() + 1).toISOString().split('T')[0];
    const minDate = new Date(today.getFullYear() - 60, today.getMonth(), today.getDate()).toISOString().split('T')[0];


    const handlenameChange = (event) => {
        
        setSelectedName(event.target.value)
    };

   
    const handlesurnameChange = (event) => {
        
        setSelectedSurName(event.target.value)
    };

    const handleemailChange = (event) => {
        
        setSelectedEmail(event.target.value)
    };

    const handlemobilenumberChange = (event) => {
        
        setSelectedMobileNumber(event.target.value)
    };
    //getgender



    







    return (
        <Fragment>

            <div className="header_section1">

                <Navbar />


                <div className="container-fluid bg-breadcrumb">
                    <div className="container text-center py-5">
                        <h3 className="abt-txt my-5">{(lnstr === 'en') ? 'Payment Details' : 'تفاصيل الدفع\n'}</h3>
                    </div>
                </div>


            </div>


            <div className="features_section">
                <div className="container">

                    <div className="row">

                        <Col md={3}></Col>

                        <Col md={6} className="myforms my-5">



                            <h4 className="px-0 py-2">{(lnstr === 'en') ? 'Select Payment Details' : 'حدد تفاصيل الدفع\n'}</h4>

                            <form onSubmit={handleSubmit}>

                                <div className="input-group mb-2 mt-2">
                                    <div className="input-group-prepend prepends">
                                        <span className="input-group-text">{t('Name')} <span style={{ color: 'red', marginLeft: '5px' }}>*</span></span>
                                    </div>
                                    <input value={selectedname} type="text" name="name"  className="form-control" onChange={handlenameChange} required/>
                                </div>
                                <div className="input-group mb-2 mt-2">
                                    <div className="input-group-prepend prepends">
                                        <span className="input-group-text">{(lnstr === 'en') ? 'Surname' : 'اسم العائلة'} <span style={{ color: 'red', marginLeft: '5px' }}>*</span></span>
                                    </div>
                                    <input value={selectedsurname} type="text" name="surname" className="form-control" onChange={handlesurnameChange} required />
                                </div>
                                <div className="input-group mb-2 mt-2">
                                    <div className="input-group-prepend prepends">
                                        <span className="input-group-text">{t('Email')} <span style={{ color: 'red', marginLeft: '5px' }}>*</span></span>
                                    </div>
                                    <input value={selectedemail} type="email" name="email" className="form-control" onChange={handleemailChange} required />
                                </div>
                                <div className="input-group mb-2 mt-2">
                                    <div className="input-group-prepend prepends">
                                        <span className="input-group-text">{(lnstr === 'en') ? 'Mobile Number' : 'رقم الهاتف المحمول'} <span style={{ color: 'red', marginLeft: '5px' }}>*</span></span>
                                    </div>
                                    <input value={selectedmobilenumber} type="phone" name="moblienumber" className="form-control" onChange={handlemobilenumberChange} />
                                </div>
                                <button type="submit" className="btn btn-primary rounded-pill text-white my-1 py-1 px-4">{t('Submit')}</button>


                            </form>


                        </Col>

                        <Col md={3}></Col>

                    </div>

                </div>
            </div>

            <Footer />

            {loading && (
                <div className="overlay">
                    <div className="spinner"></div>
                </div>
            )}

        </Fragment>
    );


}

export default LoungesPaymentPage;
