import React, { Fragment, useState, useEffect, useRef } from "react";
import Navbar from "../../Navbar";
import Footer from "../../Footer";
import { useTranslation } from 'react-i18next';
import travelimg from '../../tr.png';
import { format } from 'date-fns';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';
import SignatureCanvas from 'react-signature-canvas';
const { config } = require('../../api/api');


function DownloadSanadPolicy() {

  const base_url = config.baseURL;
  const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';

  const { t } = useTranslation();
  const vendor_key = 'c3VwZXJqZXRfc2FuYWRfcGVyc29uYWxfYWNjaWRlbnRfdmVuZG9yX2tleQ==';
  const api_headers = {
    'Authorization': 'Bearer ' + vendor_key,
    'Content-Type': 'application/json' // Ensure you're sending JSON
  }
  const [passportFile, setPassportFile] = useState(null);
  const [questionnaireFile, setQuestionnaireFile] = useState(null);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [passportFileError, setPassportFileError] = useState('');
  const [questionFileError, setquestionFileError] = useState('');

  const [dhofarData, setDhofarData] = useState(null);
  const [policyNo, setPolicyNo] = useState(null);
  const [policyLink, setPolicyLink] = useState(null);
  const [policyDetails, setPolicyDetails] = useState(null);
  const [policyCoverDetails, setPolicyCoverDetails] = useState(null);
  const [sandaUrlData, setSandaUrlData] = useState(null);
  const [benData, benAppData] = useState(false);
  const redirect_url = "https://superjetom.com/";
  const [payment_link, setPaymentLink] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(async () => {
    const urlSearchString = window.location.search;
    const uparams = new URLSearchParams(urlSearchString);
    const policy_no = uparams.get('policyno');
    setPolicyNo(policy_no);
    debugger 
    var policy_api_data = {
      "policy_no": policy_no
    };
    const response = await fetch(`${base_url}/get_sanad_policy_details_by_policy_no`, {
      method: 'POST',
      headers: api_headers,
      body: JSON.stringify(policy_api_data),
    });

    const data = await response.json();

    if (data.message === 'success') {

      debugger
      setPolicyDetails(data?.result[0]);

      var policy_cover_details = data?.result[0]?.policy_data;
      var policy_sanad_details = data?.result[0]?.sanad_data;
      if (policy_cover_details) {
        policy_cover_details = JSON.parse(policy_cover_details);
        setPolicyCoverDetails(policy_cover_details);
      } else {
        setPolicyCoverDetails(null);
      }

      if (policy_sanad_details) {
        policy_sanad_details = JSON.parse(policy_sanad_details);
        var url_data = {
          'back_url': policy_sanad_details.sanad_BackUrl,
          'return_url': policy_sanad_details.sanad_ReturnUrl,
          'error_url': policy_sanad_details.sanad_ErrorUrl,
      };
      setSandaUrlData(url_data);
      }
      // Handle successful response
      // Example: setPaymentUrl(data.result.data.payment_url);
    } else {
      setPolicyDetails(null);
      setPolicyCoverDetails(null);
      // Handle failure
      // setPolicyLink('');
    }
    // var url_data = Cookies.get('sanadUrlData');
    // debugger
    // if (url_data) {
    //   setSandaUrlData(JSON.parse(url_data));
    // }


  }, []);

  useEffect(async () => {
    debugger
    const fetchData = async () => {
      try {
        var accessTokenData = await createAccessToken();
        var accessToken = accessTokenData?.result?.data?.token;
        if (accessToken != null && accessToken != undefined) {
          debugger
          setDhofarData(accessToken);

        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);

  const createAccessToken = async () => {
    try {
      const response = await fetch(base_url + '/create_sanad_personal_accident_token', {
        method: 'GET',
        headers: api_headers
      });
      const data = await response.ok ? await response.json() : Promise.reject('Network response was not ok');
      if (data.message === 'success') {
        return data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const postData = { token: dhofarData, policy_no: policyNo };

        // const postData = { id: someId }; // Adjust as necessary
        const response = await fetch(base_url + '/get_sanad_policy_documents', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postData),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        if (data.message === 'success' && data?.result?.data?.policy_schedule_url != null) {
          setPolicyLink(data.result.data.policy_schedule_url);
        } else {
          setPolicyLink('');
        }
      } catch (error) {
        console.error('Error fetching policy documents:', error);
      }
    };

    fetchData();
  }, [dhofarData, policyNo]);

  const handleUpload = async (e) => {

    // debugger;
    e.preventDefault();
    setquestionFileError('');
    setPassportFileError('');
    const dhofar = dhofarData;
    const policy = policyNo;
    const formData = new FormData();
    formData.append('token', dhofar);
    formData.append('policy_no', policy);
    debugger
    if(passportFile == null){
      setPassportFileError('Please select Passport file ');
    }else{
      setLoading(true);
      if (passportFile !== null) {
        formData.append('passport', passportFile);
      }
  
      if (questionnaireFile !== null) {
        formData.append('questionnaire', questionnaireFile);
      }
  
      const response = await fetch(base_url + '/get_sanad_personal_accident_uploadfile', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${vendor_key}`
        },
        body: formData
      });
      const data = await response.json();
      setLoading(false);
      if (data.result.success) {
        setPassportFile(null);
        alert(data.result.message);
        handleClose();
      } else {
        alert('file is not uploaded try again');
        handleClose();
      }

    }

   
  };

  const handleFileChange = (e) => {
    // debugger;
    if (e.target.files.length === 1) {
      const file = e.target.files[0];
      const name = e.target.name;
      const fileType = file.type;
      const maxSize = 2 * 1024 * 1024; // 5MB in bytes

      // Check if the file exceeds the size limit
      if (file && file.size > maxSize) {
        alert('File size exceeds the 2MB limit.');
        return;
      }

      if (fileType.startsWith('image') || fileType === 'application/pdf') {
        if (name === 'passport' && passportFile == null) {
          setPassportFile(file);
        } else if (name === 'passport' && passportFile !== null && passportFile !== '' && passportFile !== undefined) {
          setPassportFile(null);
          setPassportFile(() => file);
        } else if (name === 'questionnaire' && questionnaireFile == null) {
          setQuestionnaireFile(file);
        } else if (name === 'questionnaire' && questionnaireFile !== null && questionnaireFile !== '' && questionnaireFile !== undefined) {
          setQuestionnaireFile(null);
          setQuestionnaireFile(() => file);
        }
      } else {
        alert('Please select a valid image or PDF file.');
      }
      document.getElementById('passport').value = '';
      document.getElementById('questionnaire').value = '';
    }
  };
  const handleFileDelete = (e) => {
    // debugger;
    const name = e.target.getAttribute('data-name');
    if (name === 'questionnaire') {
      setQuestionnaireFile(null);
      document.getElementById('questionnaire').value = '';
    } else if (name === 'passport') {
      setPassportFile(null);
      document.getElementById('passport').value = '';
    }
  };

  const handleClose = () => {
    setShowUploadModal(false);
  };

  const handleShow = () => {
    setShowUploadModal(true);
  };


  return (
    policyNo?<Fragment>
      <div className="features_section layout_padding my-5">
        <div>
          <div className="row">
            <Col md={3}></Col>
            <Col md={6} className="myforms my-3 ">
              <div style={{ display: 'flex', paddingBottom: '20px' }}>
                <button style={{ width: '100px', fontWeight: 'bold' }} onClick={() => {
                  const regex = /^(ftp|http|https):\/\/[^ "]+$/;
                  var isValidUrl = regex.test(sandaUrlData?.back_url);

                  if (isValidUrl) {
                    window.location.href = sandaUrlData?.back_url + '?policyno=' + policyDetails?.policy_no;
                    // window.open(sandaUrlData?.back_url + '?policyno=' + policyDetails?.policy_no, '_blank')
                  } else {
                    window.location.reload();
                  }

                }} >Back</button>
              </div>

              <div style={{ textAlign: 'left', fontSize: '20px' }}>
                <b>Policy Details  - {policyDetails != null ? policyDetails?.policy_no : ''}</b>
              </div>

              <br />

              <table className="table table-bordered table-condenced" style={{ textAlign: 'left' }}>

                <tbody>

                  <tr><td style={{ width: '50%' }}><b>Name</b>
                  </td><td>{policyDetails != null ? policyDetails?.first_name : ''}</td></tr>

                  <tr><td><b>Occupation</b>
                  </td><td>{policyCoverDetails != null ? policyCoverDetails?.personalInformation?.occupation?.toString() : ''}</td></tr>


                  <tr><td><b>Passport</b>
                  </td><td>{policyDetails != null ? policyDetails?.sanad_passport_no : ''}</td></tr>

                  <tr><td><b>Sanad Reference No</b>
                  </td><td>{policyDetails != null ? policyDetails?.sanad_reference_no : ''}</td></tr>

                  <tr><td><b>Civil ID</b>
                  </td><td>{policyCoverDetails != null ? policyCoverDetails?.civil_id : ''}</td></tr>

                  <tr><td><b>Total Fee (OMR)</b>
                  </td><td>{policyCoverDetails != null ? policyCoverDetails?.amount?.total?.toString() : ''}</td></tr>

                  <tr><td><b>Created</b>
                  </td><td>{policyDetails != null ? policyDetails?.created_at.toString().split('T')[0] : ''}</td></tr>

                </tbody>

              </table>
              <div className="text-center">
                              {
                                policyLink != null && policyLink != '' ?
                                  // true ?
                                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <button
                                      onClick={() => {
                                        debugger
                                        if (policyLink) {
                                          var url = policyLink;
                                          const link = document.createElement('a');
                                          link.href = url;
                                          link.target = '_blank';
                                          link.download = url.split('/').pop(); // Extracts filename from URL
                                          document.body.appendChild(link);
                                          link.click();
                                          document.body.removeChild(link);
                                        } else {
                                          alert('Policy error')
                                        }
                                      }}
                                      style={{ fontWeight: 'bold' }}
                                    >
                                      Download Policy
                                    </button>
                                   {policyCoverDetails != null && <button
                                      onClick={handleShow}
                                      style={{ fontWeight: 'bold' }}
                                    >
                                      Upload Files
                                    </button>}
              
                                    <div style={{ margin: '10px 0' }} >
                                      <button style={{ marginLeft: 'auto', fontWeight: 'bold' }}
                                        onClick={() => {
                                          debugger
                                          const regex = /^(ftp|http|https):\/\/[^ "]+$/;
                                          var isValidUrl = regex.test(sandaUrlData?.return_url);
              
                                          if (isValidUrl) {
                                            window.location.href =
                                              sandaUrlData?.return_url +
                                              '?policyno=' + policyDetails?.policy_no +
                                              '&sanad_reference_no=' + policyDetails?.sanad_reference_no +
                                              '&policy_link=' + policyLink;
                                            // window.open(sandaUrlData?.return_url+ '?policyno=' + policyDetails?.policy_no+'&sanad_reference_no='+policyDetails?.sanad_reference_no+'&policy_link='+policyLink, '_blank')
                                          } else {
                                            window.location.reload();
                                          }
              
                                        }}
                                      >Go To Sanad</button>
                                    </div></div>
                                  : policyLink === '' ? <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}><h2>Policy Payment Pending</h2>
                                    <div>
                                      <button
                                        onClick={() => {
              
                                          if (policyDetails?.payment_link) {
                                            window.location.href = policyDetails?.payment_link;
              
                                            // Reload the current page (form tab)
                                            // window.location.reload();
                                          }
              
                                        }} style={{ marginLeft: 'auto', width: '200px', fontWeight: 'bold' }}>Make Payment</button></div></div> : "Loading"
                              }
                            </div>
                            <p></p>
                           {/* {!policyLink &&(<p style={{ color: "red" }}>(This payment link is valid for a maximum of 24 hours. If the link expires, please create a new policy.\nرابط الدفع هذا صالح لمدة أقصاها 24 ساعة. إذا انتهت صلاحية الرابط، يرجى إنشاء سياسة جديدة.) </p>)} */}
                           {!policyLink&&( <label style={{ color: "red" ,display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                           <span>
                           This payment link is valid for a maximum of 24 hours. If the link expires, please create a new policy.
                           </span>
                           <span style={{ textAlign: "right", direction: "rtl" }}>
                           رابط الدفع هذا صالح لمدة أقصاها 24 ساعة. إذا انتهت صلاحية الرابط، يرجى إنشاء سياسة جديدة.
                           </span>
                       </label>)}
                        </Col>
                          <Col md={3}></Col>
                          
                        </div>
                      </div>
                    </div>
                    {showUploadModal && (
                        <div className="modal fade show" style={{ display: 'block' }} id="uploadModal">
                          <div className="modal-dialog modal-dialog-centered modal-lg" style={{ left: '10%' }}>
                            <div className="modal-content">
                              <div className="modal-header">
                                <h2 className="modal-title">
                                  <span>
                                    {t('Upload')} {t('Documents')}
                                  </span>
                                  <p style={{color:'red',marginLeft:'25px'}}>
                                Please upload a file format only Image (or) Pdf
                                </p>
                                </h2>
                                <button type="button" className="close" onClick={handleClose}>
                                  &times;
                                </button>
                              </div>
                              <div className="modal-body">
                                <form onSubmit={(e) => handleUpload(e)}>
                                  <div className="form-group row">
                                    <label className="col-sm-12 col-md-8 col-form-label text-left">
                                      <strong>{t('Passport copy With visa page or Resident ID')}</strong>
                                      {/* <br /> <small>{t('(With visa page or resident ID)')}</small> */}
                                    </label>
                                    <div className="col-sm-12 col-md-4">
                                      {passportFile === null ? (
                                        <button  onClick={() => document.getElementById('passport').click()}>
                                          {t('Upload File')}
                                        </button>
                                      ) : (
                                        <div className="d-flex border mt-2 align-items-center p-2 rounded">
                                          <p className="mb-0" style={{ width: '80%' }}>
                                            {passportFile.name.length > 5
                                              ? `${passportFile.name.substring(0, 5)}...${passportFile.type.split('/')[1]}`
                                              : passportFile.name}
                                          </p>
                                          <span
                                            className="text-danger ms-auto"
                                            onClick={handleFileDelete}
                                            data-name="passport"
                                            style={{ cursor: 'pointer', width: '20%' }}>
                                            &times;
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  {passportFileError && <p style={{ color: 'red' }}>{passportFileError}</p>} {/* Show error message */}
                
                                  {/* <div className="form-group row mt-3">
                                    <label className="col-sm-12 col-md-8 col-form-label text-left">
                                      <strong>{t('Filled questionnaire')}</strong>
                                    </label>
                                    <div className="col-sm-12 col-md-4">
                                      {questionnaireFile === null ? (
                                        <button  onClick={() => document.getElementById('questionnaire').click()}>
                                          {t('Upload File')}
                                        </button>
                                      ) : (
                                        <div className="d-flex border mt-2 align-items-center p-2 rounded">
                                          <p className="mb-0" style={{ width: '80%' }}>
                                            {questionnaireFile.name.length > 5
                                              ? `${questionnaireFile.name.substring(0, 5)}...${questionnaireFile.type.split('/')[1]}`
                                              : questionnaireFile.name}
                                          </p>
                                          <span
                                            className="text-danger ms-auto"
                                            onClick={handleFileDelete}
                                            data-name="questionnaire"
                                            style={{ cursor: 'pointer', width: '20%' }}>
                                            &times;
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  {questionFileError && <p style={{ color: 'red' }}>{questionFileError}</p>} */}
                                  <input
                                    type="file"
                                    name="passport"
                                    id="passport"
                                    accept="application/pdf,image/*"
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange}
                                  />
                                  <input
                                    type="file"
                                    name="questionnaire"
                                    id="questionnaire"
                                    accept="application/pdf,image/*"
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange}
                                  />
                
                                  <div className="mt-4 text-center">
                                    <button type="submit" >
                                      {t('  Submit File ')}
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                        {loading && (
                              <div className="overlay">
                                  <div className="spinner"></div>
                              </div>
                          )}
    </Fragment>: <div style={{
           
            border: 'none',
            textAlign: 'center',
            marginTop: '25%',
            fontSize: '20px',
            fontWeight: 'bold'
        }}>  { "Policy Number Invalid"}</div>
  );
}

export default DownloadSanadPolicy;