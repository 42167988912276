import React, { Fragment, useState, useEffect, useRef } from "react";
import axios from "axios";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { useTranslation } from 'react-i18next';
import travelimg from '../tr.png';
import { format } from 'date-fns';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';
import SignatureCanvas from 'react-signature-canvas';
const { config } = require('../api/api');


function DownloadLoungesPolicy() {

  const base_url = config.baseURL;
  const redirect_url = config.redirectURL;
  const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const [vendorKey, setVendorkey] = useState('');
  const [dhofarData, setDhofarData] = useState(false);
  const [downloadpolicy, setDownloadValue] = useState('');
  const [policyLink, setPolicyLink] = useState(null);
  const [policy_no, setPolicyNo] = useState(null);
  const [contract_id, setcontractsID] = useState('');
  // const [showUploadModal, setShowUploadModal] = useState(false);

  const [loungescoupon, setLoungesToken] = useState('');

  const [passportFile, setPassportFile] = useState(null);
  const [questionnaireFile, setQuestionnaireFile] = useState(null);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [fileChoosen,setFileChoosen]=useState(true);

  const pattern = /^(https?:\/\/)?([a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+)(:[0-9]{1,5})?(\/.*)?$/;

  useEffect(() => {
    const urlSearchString = window.location.search;
    const uparams = new URLSearchParams(urlSearchString);
    const policy_no = uparams.get('order_id');
    setPolicyNo(policy_no);
  }, []);

    useEffect(() => {
      if (showUploadModal) {
        document.body.style.overflow = 'hidden'; 
      } else {
        document.body.style.overflow = 'auto';
      }
      
      return () => {
        document.body.style.overflow = 'auto'; 
      };
    }, [showUploadModal]);


  useEffect(() => {
    const createOrganization = async () => {
      try {
        const postvData = { organization_name: "Superjet" };

        const response = await fetch(base_url + '/create_organization', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(postvData),
        });
        const data = await response.json();
        setVendorkey(data.result[0].vendor_key);
      } catch (error) {
        console.error('Error posting data:', error);
      }
    };

    if (!vendorKey) {
      createOrganization();
    }
  }, [vendorKey, base_url]);
  const postData = {
    "limit": 20,
    "offset": 0
  };


  useEffect(() => {
    debugger

    if (vendorKey !== '') {
      fetch(base_url + '/get_contracts', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${vendorKey}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),

      })
        .then(response => response.json())
        .then(data => {

          setcontractsID(data.result.results[0].id);

        })
        .catch(error => {
          console.error('Error posting data:', error);
        });

    }
  }, [vendorKey]);

  useEffect(() => {
    const fetchPolicyDetails = async () => {
      if (vendorKey && contract_id  && policy_no) {
        try {
          const quotation_data = {
            contract_id: contract_id,
            order_id: policy_no,
          };

          const response = await fetch(base_url + '/get_order_details', {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${vendorKey}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(quotation_data),
          });

          const data = await response.json();
          setDownloadValue(data.result.orderItems);

        } catch (error) {
          console.error('Error fetching policy details:', error);
        }
      }
    };

    fetchPolicyDetails();
  }, [vendorKey, contract_id, policy_no, base_url]);



  // const handleQotation = async (itemvalue) => {



  //   debugger

  //   var quotation_data =
  //   {
  //       orderItemId: itemvalue,
  //       orderId: policy_no,
  //   };
  //   debugger
  //   if (vendorKey !== '') {
  //     // const fetchPolicy = async () => {
  //     try {


  //       const response = await fetch(base_url + '/generate_coupon', {
  //         method: 'POST',
  //         headers: {
  //           'Authorization': `Bearer ${vendorKey}`,
  //           'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify(quotation_data),
  //       });

  //       if (!response.ok) {
  //         throw new Error('Network response was not ok');
  //       }
  //       debugger
  //       const blob = new Blob([response.data], { type: "application/pdf" });
  //       const url = window.URL.createObjectURL(blob);
  
  //       // Create a link and trigger download
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute("download", "order_coupon.pdf");
  //       document.body.appendChild(link);
  //       link.click();
  
  //       // Clean up resources
  //       document.body.removeChild(link);
  //       window.URL.revokeObjectURL(url);

  //     } catch (error) {
  //       console.error('Error fetching occupations:', error);
  //     }
  //     // };


  //   }
  // };

  // const handleQotation = async (itemvalue) => {
  //   var quotation_data = {
  //     orderItemId: itemvalue,
  //     orderId: policy_no,
  //   };
  
  //   if (vendorKey !== "") {
  //     try {
  //       const response = await fetch(base_url + "/generate_coupon", {
  //         method: "POST",
  //         headers: {
  //           "Authorization": `Bearer ${vendorKey}`,
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify(quotation_data),
  //       });
  
  //       if (!response.ok) {
  //         throw new Error("Network response was not ok");
  //       }
  //       debugger
  //       const data = await response.json();
  
  //       // ✅ Get the blob correctly
  //       const blob = await data.result;
  
  //       // ✅ Create a download link
  //       const url = window.URL.createObjectURL(blob);
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute("download", "order_coupon.png");
  //       document.body.appendChild(link);
  //       link.click();
  
  //       // ✅ Cleanup
  //       document.body.removeChild(link);
  //       window.URL.revokeObjectURL(url);
        
  //     } catch (error) {
  //       console.error("Error generating quotation PDF:", error);
  //     }
  //   }
  // };
  
//   const downloadPDF = async () => {
//     try {
//       const response = await axios.post(
//         "https://api.easyto.travel/api/v1/agent/order/coupon/generate",
//         {
//           orderId: "OZGFYNID",
//           orderItemId: "fRsRmfsf",
//         },
//         {
//           headers: {
//             Authorization: "API-KEY",
//             "Content-Type": "application/json",
//             "x-api-key": "P-fdOo9DyrA8SFKLfxuzLz9kJZ3p2v3sm1",
//           },
//           responseType: "blob", // Important to receive file as binary data
//         }
//       );
// debugger
//       // Create a Blob URL
//       const blob = new Blob([response.data], { type: "application/pdf" });
//       const url = window.URL.createObjectURL(blob);

//       // Create a link and trigger download
//       const link = document.createElement("a");
//       link.href = url;
//       link.setAttribute("download", "order_coupon.pdf");
//       document.body.appendChild(link);
//       link.click();

//       // Clean up resources
//       document.body.removeChild(link);
//       window.URL.revokeObjectURL(url);
//     } catch (error) {
//       console.error("Error downloading PDF:", error);
//     }
//   };



  // const handleSubmit = async (event) => {
  //   event.preventDefault();

  //   window.location.href = '/insurance';

  // };

  // const handlerepayment = async (event) => {
  //   // event.preventDefault();
  //   handleQotation();
  // }

  const handleQotation = async (itemvalue) => {
    var quotation_data = {
      orderItemId: itemvalue,
      orderId: policy_no,
    };
  
    if (vendorKey !== "") {
      try {
        const response = await fetch(base_url + "/generate_coupon", {
          method: "POST",
          headers: {
            "Authorization": `Bearer ${vendorKey}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(quotation_data),
          responseType: "blob", // Fetch response as a binary file
        });
  
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
  
        // Convert response to blob
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
  
        // Create a download link
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "order_coupon.pdf"); // Ensure correct file type
        document.body.appendChild(link);
        link.click();
  
        // Cleanup
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
  
      } catch (error) {
        console.error("Error generating quotation PDF:", error);
      }
    }
  };
  

      useEffect(() => {
        if (showUploadModal) {
          document.body.style.overflow = 'hidden'; 
        } else {
          document.body.style.overflow = 'auto';
        }
        
        return () => {
          document.body.style.overflow = 'auto'; 
        };
      }, [showUploadModal]);


  const isValidUrl = (url) => {
    return pattern.test(url);
  };

  return (



    <Fragment>

      <div className="header_section1">

        <Navbar />


        <div className="container-fluid bg-breadcrumb">
          <div className="container text-center py-5">
            <h3 className="abt-txt my-5">{(lnstr === 'en') ? 'Download Coupon ' : 'تحميل السياسة\n'}</h3>
          </div>
        </div>


      </div>


      <div className="features_section">
        <div className="container">

          <div className="row">

            <Col md={3}></Col>

            <Col md={6} className="myforms my-5" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

              <br />

              {/* <table className="table table-bordered table-condenced" style={{ textAlign: 'left' }}>

                <tbody>

                  <tr><td style={{ width: '50%' }}><b>Name</b>
                  </td><td>{downloadpolicy != null ? downloadpolicy?.first_name : ''}</td></tr>

                  <tr><td><b>Email</b>
                  </td><td>{downloadpolicy != null ? downloadpolicy?.email : ''}</td></tr>



                  <tr><td><b>Passport</b>
                  </td><td>{downloadpolicy != null ? downloadpolicy?.passport : ''}</td></tr>


                  <tr><td><b>Cover Type</b>
                  </td><td>{downloadpolicy != null ? downloadpolicy.policy_data?.cover_type : ''}</td></tr>

                  <tr><td><b>Civil ID</b>
                  </td><td>{downloadpolicy != null ? downloadpolicy.policy_data?.civil_id : ''}</td></tr>



                </tbody>

              </table> */}

              {/* <table className='table table-bordered mt-3'>
                      <thead>
                        <tr>
                          <th>S/No</th>
                          <th>Name</th>
                          <th>SurName</th>
                          <th>Email</th>
                          <th>Date</th>
                          <th>Paxtype</th>
                          <th>Coupon</th>
                        </tr>
                      </thead>
                      <tbody>
                        {downloadpolicy.length === 0 ? (
                          <tr>
                            <td colSpan={10} className='text-center'>
                              {t('No')} {t('Records')} {t('Found')}
                            </td>
                          </tr>
                        ) : (
                            downloadpolicy.map((prof, index) => (
                            <tr key={index}>
                                <td>{index+1}</td>
                              <td>{prof.firstName}</td>
                              <td>{t(prof.lastName)}</td>
                              <td>{t(prof.email)}</td>
                              <td>{format(new Date(prof.date), 'dd/MM/yyyy')}</td>
                              <td>{t(prof.paxType)}</td>
                              
                              <td><Button variant="primary" style={{ fontWeight: 'normal' }}
                                className="btn btn-primary rounded-pill text-white py-1 px-2"
                                onClick={() => handleQotation(prof.id)} 
                                // onClick={ ()=> downloadPDF()} 
                                value={prof.policy_no}
                              >
                                {t('Download')}
                              </Button>
                              </td>
                            </tr>
                          ))
                        )}
              
                      </tbody>
                    </table> */}
                  

                <table className="table table-bordered mt-3">
                  <thead>
                    <tr>
                      <th>S/No</th>
                      <th>{t("FirstName")}</th>
                      <th>{t("Last Name")}</th>
                      <th>{t("Email")}</th>
                      <th>{t("Date")}</th>
                      <th>{t("Paxtype")}</th>
                      <th>{t("Coupon")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {downloadpolicy.length === 0 ? (
                      <tr>
                        <td colSpan={7} className="text-center">
                          {t("No")} {t("Records")} {t("Found")}
                        </td>
                      </tr>
                    ) : (
                      downloadpolicy.map((prof, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{t(prof.firstName)}</td>
                          <td>{t(prof.lastName)}</td>
                          <td>{prof.email}</td> {/*  Email shouldn't be translated */}
                          <td>{format(new Date(prof.date), "dd/MM/yyyy")}</td>
                          <td>{t(prof.paxType)}</td>
                          <td>
                            <Button
                              variant="primary"
                              className="btn btn-primary rounded-pill text-white py-1 px-2"
                              onClick={() => handleQotation(prof.id)} // Ensure this is correct
                            >
                              {t("Download")}
                            </Button>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>


              

            </Col>
            <Col md={2}></Col>
          </div>
        </div>
      </div>
      <Footer />

      

      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}

    </Fragment>


  );
}

export default DownloadLoungesPolicy;