import React, { Fragment, useState, useEffect, useRef } from "react";
import Navbar from "../Navbar";
import Cookies from "js-cookie";
import Footer from "../Footer";

import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { event } from "jquery";
const { config } = require("../api/api");

function Ownerpagedetails() {
  const base_url = config.baseURL;



  const lnstr = Cookies.get("langData") ? Cookies.get("langData") : "en";
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

 
  const [formData, setFormData] = useState([]);

  const [questionValue, setQuestionValue] = useState({

    property_in_basement:"",
    whole_building_residential:"",
    fire_alarm_system:"",
    portable_extinguishers:"",
    hydrant_system:"",
    other_fire_protection:"",
    surveillance_camera:"",
    burglar_alarm_system:"",
    grilled_doors:"",
    watchman_service:"",
    security_checkpoint:"",
    other_security_system:"",

  });

  const [vendorKey, setVendorkey] = useState("");
  
  const ageBuildingRef=useRef(null);
  const typeBuildingRef=useRef(null);
  const  floorNoRef=useRef(null);
  const  addressRef=useRef(null);
  const refs=useRef({})
  const  [errors,setErrors]=useState({});
  useEffect(() => {
    const postvData = {
      organization_name: "Superjet"
    };
   

    if (vendorKey === "" || vendorKey === null || vendorKey === undefined) {
      fetch(base_url + "/create_organization", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(postvData)
      })
        .then((response) => response.json())
        .then((data) => {
          setVendorkey(data.result[0].vendor_key);
        })
        .catch((error) => {
          console.error("Error posting data:", error);
        });
    }
  }, [vendorKey]);

  const [dhofarData, setDhofarData] = useState(false);
  useEffect(() => {
    const storedDhofarString = Cookies.get("homeData"); //sessionStorage.getItem('userData')
   
    if (storedDhofarString !== undefined) {
      const storedDhofar = JSON.parse(storedDhofarString);
      setDhofarData(storedDhofar);
    } else {
      // alert("token expired");
      // window.location.href='/';
    }
  }, []);

  const postData = {
    token: dhofarData
  };
  useEffect(() => {
  
     
    if (vendorKey !== "") {
      fetch(base_url + "/home_details", {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${vendorKey}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(postData)
      })
        .then((response) => response.json())
        .then((data) => {
        
          setFormData(data.result.data);
        })
        .catch((error) => {
          console.error("Error posting data:", error);
        });
    }
  
  }, [vendorKey]);

  useEffect(()=>{
     if(formData.length>0){
       setLoading(false);
       }
      else{
        setLoading(true);
       }
  },[formData]);
  

  const handleSubmit = async (event) => {
    event.preventDefault();
  var field=false;
  var question=false;
    var data = {
      ...questionValue,
      "age_of_building": agebuilding,
      "type_of_building": buildingtype,
      "floor_no": floorno,
      "address": address,
    };
    const {other_security_system,other_fire_protection,...filteredQuestions}=questionValue;
    if(data.age_of_building===""||data.age_of_building===null||data.age_of_building===undefined){
          ageBuildingRef.current.focus();
          setErrors((data)=>({...data,ageOfBuildingError:"Please enter valid Age of Building, it accept numbers only."}));
          return;
    }
    else if(data.type_of_building===""||data.type_of_building===null||data.type_of_building===undefined){
      typeBuildingRef.current.focus();
      setErrors((data)=>({...data,typeOfBuildingErr:" Please enter valid details"}));
      return;
    }
    else if(data.floor_no===""||data.floor_no===null||data.floor_no===undefined){
      floorNoRef.current.focus();
      setErrors((data)=>({...data,floorNoErr:"Please enter valid details"}));
      return;
    }
    else if(data.address===""||data.address===null||data.address===undefined){
      addressRef.current.focus();
      setErrors((data)=>({...data,addressOfPropertyErr:"Please enter valid details"}));
      return;
    }
    else{
        field=true;
    }
   if(filteredQuestions){
  for(let prop in filteredQuestions){
    if(filteredQuestions[prop]===""||filteredQuestions[prop]===null||filteredQuestions[prop]===undefined){
       const refElement = refs.current[prop];
          if (refElement && refElement.scrollIntoView) {
            alert("Please ensure all questions.");
            refElement.scrollIntoView({ behavior: "smooth", block: "center" });
          } 
      question=false;
      return;
    }
  }
  question=true;
}
  if(field && question){
    sessionStorage.setItem("homedetails", JSON.stringify(data));
    setLoading(true);
    // Check if the selected option is 'Personal Accident'
    window.location.href = "/home-insurance-photograph";
   }
  };



  useEffect(()=>{
    const homeOwnerPageInfo=sessionStorage.getItem("homedetails");
    if(homeOwnerPageInfo!==undefined){
       const ownerJson= JSON.parse(homeOwnerPageInfo);
       if(ownerJson){
           setBuildingType(ownerJson.type_of_building);
           setaAddressValue(ownerJson.address);
           setagebuildingValue(ownerJson.age_of_building);
           setFloorNo(ownerJson.floor_no)
           for (let prop in ownerJson) {
                if (questionValue.hasOwnProperty(prop)) {
                    setQuestionValue((prevData) => ({
                        ...prevData,
                        [prop]: ownerJson[prop],
                    }));
                }
            }
       }
    }
  },[])
  const [agebuilding, setagebuildingValue] = useState("");

  const handleChangeage = (e) => {
    const value=e.target.value;
    const replaceAgeBuilding=value.replace(/[^0-9]/g,"")
    setagebuildingValue(replaceAgeBuilding);
    if(replaceAgeBuilding.trim()!==""){
      setErrors((data)=>({...data,ageOfBuildingError:""}))
    }
   
  };


  const handleError=(e)=>{
    const name=e.target.name;
    const value=e.target.value;
    const repAgeBuilding=value.replace(/[^0-9]/g,"")
    
   if(repAgeBuilding.trim()===""&&name==="age_of_building"){
      setErrors((data)=>({...data,ageOfBuildingError:"Please enter valid Age of Building, it accept numbers only."}))
    }

   if((address===""||address===null||address===undefined)&&name==="address"){
      setErrors((data)=>({...data,addressOfPropertyErr:"Please enter valid details"}))
   }
    
  }
  const handleSelectError=(e)=>{
    const name=e.target.name;

     if(name==="type_of_building"&&(buildingtype===""||buildingtype===null||buildingtype===undefined)){
      setErrors((data)=>({...data,typeOfBuildingErr:" Please enter valid details"}))
     }
     if(name==="type_of_building"&&(buildingtype!==""&&buildingtype!==null&&buildingtype!==undefined)){
      setErrors((data)=>({...data,typeOfBuildingErr:""}));
     }

     if(name==="floor_no" && (floorno===""||floorno===null||floorno===undefined)){
      setErrors((data)=>({...data,floorNoErr:"Please enter valid details"}));
     }
     if(name==="floor_no" && (floorno!==""&&floorno!==null&&floorno!==undefined)){
      setErrors((data)=>({...data,floorNoErr:""}));
     }
  }

  const [buildingtype, setBuildingType] = useState("");
  const handleChangeBuildingType = (e) => {
    // setFormData({ ...formData, [name]: value });
    setBuildingType(e.target.value);
  };

  const [floorno, setFloorNo] = useState("");
  const handleChangeFloorno = (e) => {
    // setFormData({ ...formData, [name]: value });
    setFloorNo(e.target.value);    
  };

  const [address, setaAddressValue] = useState("");

  const handleChangeAddress = (event) => {
    const name=event.target.name;
    const addressProperty=event.target.value.trimStart();
    setaAddressValue(event.target.value.trimStart());
    if((addressProperty!==""&&addressProperty!==null&&addressProperty!==undefined)&&name==="address"){
      setErrors((data)=>({...data,addressOfPropertyErr:""}));
   }
  };

  const handleQuestionOption = (e, name) => {
   
    const value = e.target.value;
    setQuestionValue((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <Fragment>
      <div className="header_section1">
        <Navbar />
        <div className="container-fluid bg-breadcrumb">
          <div className="container text-center py-5">
            <h3 className="abt-txt my-5">{lnstr === "en" ? "Home Insurance" : "Home Insurance\n"}</h3>
          </div>
        </div>
      </div>

      <div className="features_section">
        <div className="container">
          <div className="row">
            <Col md={3}></Col>

            <Col md={6} className="myforms my-5">
              <div>
                <h1>Property Insurance Form</h1>
                <form>
                  {formData?.map((item) => (
                    <div key={item.key}>
                      <form onSubmit={" "}>
                        {item.type === "Number" && item.key === "age_of_building" && (
                          <>
                          <div className="input-group mb-2 mt-2">
                            <div className="input-group-prepend prepends"></div>
                            <input
                              type="number"
                              ref={ageBuildingRef}
                              placeholder={lnstr === "en" ? item.text_english : item.text_arabi}
                              name={item.key}
                              className="form-control"
                              value={agebuilding}
                              onChange={handleChangeage}
                              onBlur={handleError}
                              required
                            />
                          </div>
                          <div className="text-danger p-0 mb-3">{errors?.ageOfBuildingError}</div>
                          </>
                        )}

                        {item.type === "Select" && item.key === "type_of_building" && (
                          <>
                          <div className="input-group mb-2 mt-2 w-100">
                            <div className="input-group-prepend prepends"></div>
                            <select className="form-control"style={{width: '100%'}} 
                            ref={typeBuildingRef}
                              name={item.key} value={buildingtype} onChange={handleChangeBuildingType} onBlur={handleSelectError} onFocus={()=>{  setErrors((data)=>({...data,typeOfBuildingErr:""})); }}required>
                              <option value="" disabled selected>
                                {lnstr === "en" ? item.text_english : item.text_arabi}
                              </option>
                              {item.options.map((option, index) => (
                                <option key={index} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className={errors?.typeOfBuildingErr?"text-danger mb-2":"text-black mb-2"}>House/ Villa, Apartment/ Flat or Others.{errors?.typeOfBuildingErr}</div>
                          </>
                        )}

                        {item.type === "Select" && item.key === "floor_no" && (
                          <>
                          <div className="input-group mb-2 mt-2">
                            <div className="input-group-prepend prepends"></div>
                            <select className="form-control" name={item.key} 
                            ref={floorNoRef}
                            value={floorno} onChange={handleChangeFloorno} 
                            onBlur={handleSelectError} onFocus={()=>{  setErrors((data)=>({...data,floorNoErr:""})); }}required>
                              <option value="" disabled selected>
                                {lnstr === "en" ? item.text_english : item.text_arabi}
                              </option>
                              {item.options.map((option, index) => (
                                <option key={index} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="text-danger p-0 mb-2">{errors?.floorNoErr}</div>
                          </>
                        )}

                        {item.type === "Yes-No" && item.key === "is_property_in_basement" && (
                        <div key={"property_in_basement"} ref={(el) => (refs.current["property_in_basement"] = el)}>
  
                            <p>{lnstr === "en" ? item.text_english : item.text_arabi}</p>
                            <div
                              className="custom-radio"
                              style={{
                                color: "#3fd2f2"
                              }}>
                              <input
                                type="radio"
                                id="yes"
                                name="question"
                                checked={questionValue?.property_in_basement === "Yes"}
                                value={"Yes"}
                                onChange={(e) => handleQuestionOption(e, "property_in_basement")}
                              />
                              <label for="yes">Yes</label>

                              <input
                                type="radio"
                                id="no"
                                name="question"
                                checked={questionValue.property_in_basement==="No"}
                                value="No"
                                onChange={(e) => handleQuestionOption(e, "property_in_basement")}
                              />
                              <label for="no">No</label>
                            </div>{" "}
                            <br></br>
                          </div>
                        )}

                        {item.type === "Yes-No" && item.key === "is_whole_building_residential" && (
                          <div key={"whole_building_residential"} ref={(el) => (refs.current["whole_building_residential"] = el)}>
                            <p>{lnstr === "en" ? item.text_english : item.text_arabi}</p>
                            <div
                              className="custom-radio"
                              style={{
                                color: "#3fd2f2"
                              }}>
                              <input
                                type="radio"
                                id="yes1"
                                name="question1"
                                checked={questionValue?.whole_building_residential === "Yes"}
                                value="Yes"
                                onChange={(e) => handleQuestionOption(e, "whole_building_residential")}
                              />
                              <label for="yes1">Yes</label>

                              <input
                                type="radio"
                                id="no1"
                                name="question1"
                                checked={questionValue?.whole_building_residential === "No"}
                                value="No"
                                onChange={(e) => handleQuestionOption(e, "whole_building_residential")}
                              />
                              <label for="no1">No</label>
                            </div>
                            <br></br>
                          </div>
                        )}

                        {item.type === "Text" && item.key === "address" && (
                        <>
                          <div className="input-group mb-2 mt-2">
                            <div className="input-group-prepend prepends"></div>
                            <textarea
                              placeholder={lnstr === "en" ? item.text_english : item.text_arabi}
                              name={item.key}
                              ref={addressRef}
                              className="form-control"
                              value={address}
                              onChange={handleChangeAddress}
                              onBlur={handleError}
                              required
                            />
                          </div>
                          <div className="text-danger p-0 mb-2">{errors?.addressOfPropertyErr}</div>
                          <br></br> <h2 class="heading-mt mt-md-4">Type of Fire protection devices installed in premises</h2>
                         </>
                        )}

                        {item.type === "Yes-No" && item.key === "have_fire_alarm_system" && (
                          <div key={"fire_alarm_system"} ref={(el) => (refs.current["fire_alarm_system"] = el)}>
                            <p>{lnstr === "en" ? item.text_english : item.text_arabi}</p>
                            <div
                              className="custom-radio"
                              style={{
                                color: "#3fd2f2"
                              }}>
                              <input
                                type="radio"
                                id="yes2"
                                name="question2"
                                checked={questionValue?.fire_alarm_system === "Yes"}
                                value="Yes"
                                onChange={(e) => handleQuestionOption(e, "fire_alarm_system")}
                              />
                              <label for="yes2">Yes</label>

                              <input
                                type="radio"
                                id="no2"
                                name="question2"
                                checked={questionValue?.fire_alarm_system === "No"}
                                value="No"
                                onChange={(e) => handleQuestionOption(e, "fire_alarm_system")}
                              />
                              <label for="no2">No</label>
                            </div>
                            <br></br>
                          </div>
                        )}

                        {item.type === "Yes-No" && item.key === "have_portable_extinguishers" && (
                          <div key={"portable_extinguishers"} ref={(el) => (refs.current["portable_extinguishers"] = el)}>
                            <p>{lnstr === "en" ? item.text_english : item.text_arabi}</p>
                            <div
                              className="custom-radio"
                              style={{
                                color: "#3fd2f2"
                              }}>
                              <input
                                type="radio"
                                id="yes3"
                                name="question3"
                                checked={questionValue?.portable_extinguishers=== "Yes"}
                                value="Yes"
                                onChange={(e) => handleQuestionOption(e, "portable_extinguishers")}
                              />
                              <label for="yes3">Yes</label>

                              <input
                                type="radio"
                                id="no3"
                                name="question3"
                                checked={questionValue?.portable_extinguishers=== "No"}
                                value="No"
                                onChange={(e) => handleQuestionOption(e, "portable_extinguishers")}
                              />
                              <label for="no3">No</label>
                            </div>
                            <br></br>
                          </div>
                        )}
                        {item.type === "Yes-No" && item.key === "have_hydrant_system" && (
                          <div key={"hydrant_system"} ref={(el) => (refs.current["hydrant_system"] = el)}>
                            <p>{lnstr === "en" ? item.text_english : item.text_arabi}</p>
                            <div
                              className="custom-radio"
                              style={{
                                color: "#3fd2f2"
                              }}>
                              <input
                                type="radio"
                                id="yes4"
                                name="question4"
                                checked={questionValue?.hydrant_system=== "Yes"}
                                value="Yes"
                                onChange={(e) => handleQuestionOption(e, "hydrant_system")}
                              />
                              <label for="yes4">Yes</label>

                              <input type="radio" id="no4" name="question4" 
                                checked={questionValue?.hydrant_system=== "No"}
                              value="No" onChange={(e) => handleQuestionOption(e, "hydrant_system")} />
                              <label for="no4">No</label>
                            </div>
                            <br></br>
                          </div>
                        )}

                        {item.type === "Text" && item.key === "other_fire_protection" && (
                          <div className="input-group mb-2 mt-2">
                            <div className="input-group-prepend prepends"></div>
                            <input
                              type="text"
                              placeholder={lnstr === "en" ? item.text_english : item.text_arabi}
                              name="registration"
                              className="form-control"
                              value={questionValue?.other_fire_protection}
                              onChange={(e) => handleQuestionOption(e, "other_fire_protection")}
                              required
                            />
                            <br></br>
                          </div>
                        )}

                        {item.type === "Yes-No" && item.key === "have_surveillance_camera" && (
                          <div key={"surveillance_camera"} ref={(el) => (refs.current["surveillance_camera"] = el)}>
                            <br />
                            <h1>Security systems provided in the premises</h1>

                            <p>{lnstr === "en" ? item.text_english : item.text_arabi}</p>
                            <div
                              className="custom-radio"
                              style={{
                                color: "#3fd2f2"
                              }}>
                              <input
                                type="radio"
                                id="yes5"
                                name="question5"
                                checked={questionValue?.surveillance_camera=== "Yes"}
                                value="Yes"
                                onChange={(e) => handleQuestionOption(e, "surveillance_camera")}
                              />
                              <label for="yes5">Yes</label>

                              <input
                                type="radio"
                                id="no5"
                                name="question5"
                                checked={questionValue?.surveillance_camera=== "No"}
                                value="No"
                                onChange={(e) => handleQuestionOption(e, "surveillance_camera")}
                              />
                              <label for="no5">No</label>
                            </div>
                            <br></br>
                          </div>
                        )}

                        {item.type === "Yes-No" && item.key === "have_burglar_alarm_system" && (
                          <div key={"burglar_alarm_system"} ref={(el) => (refs.current["burglar_alarm_system"] = el)}>
                            <p>{lnstr === "en" ? item.text_english : item.text_arabi}</p>
                            <div
                              className="custom-radio"
                              style={{
                                color: "#3fd2f2"
                              }}>
                              <input
                                type="radio"
                                id="yes6"
                                name="question6"
                                checked={questionValue?.burglar_alarm_system=== "Yes"}
                                value="Yes"
                                onChange={(e) => handleQuestionOption(e, "burglar_alarm_system")}
                              />
                              <label for="yes6">Yes</label>

                              <input
                                type="radio"
                                id="no6"
                                name="question6"
                                checked={questionValue?.burglar_alarm_system=== "No"}
                                value="No"
                                onChange={(e) => handleQuestionOption(e, "burglar_alarm_system")}
                              />
                              <label for="no6">No</label>
                            </div>
                            <br></br>
                          </div>
                        )}

                        {item.type === "Yes-No" && item.key === "have_grilled_doors" && (
                          <div key={"grilled_doors"} ref={(el) => (refs.current["grilled_doors"] = el)}>
                            <p>{lnstr === "en" ? item.text_english : item.text_arabi}</p>
                            <div
                              className="custom-radio"
                              style={{
                                color: "#3fd2f2"
                              }}>
                              <input type="radio" id="yes7" name="question7" value="Yes"
                                checked={questionValue?.grilled_doors=== "Yes"}
                              onChange={(e) => handleQuestionOption(e, "grilled_doors")} />
                              <label for="yes7">Yes</label>

                              <input type="radio" id="no7" name="question7"
                                checked={questionValue?.grilled_doors=== "No"}
                               value="No" onChange={(e) => handleQuestionOption(e, "grilled_doors")} />
                              <label for="no7">No</label>
                            </div>
                            <br></br>
                          </div>
                        )}

                        {item.type === "Yes-No" && item.key === "have_watchman_service" && (
                          <div key={"watchman_service"} ref={(el) => (refs.current["watchman_service"] = el)}>
                            <p>{lnstr === "en" ? item.text_english : item.text_arabi}</p>
                            <div
                              className="custom-radio"
                              style={{
                                color: "#3fd2f2"
                              }}>
                              <input
                                type="radio"
                                id="yes8"
                                name="question8"
                                checked={questionValue?.watchman_service=== "Yes"}
                                value="Yes"
                                onChange={(e) => handleQuestionOption(e, "watchman_service")}
                              />
                              <label for="yes8">Yes</label>

                              <input
                                type="radio"
                                id="no8"
                                name="question8"
                                checked={questionValue?.watchman_service=== "No"}
                                value="No"
                                onChange={(e) => handleQuestionOption(e, "watchman_service")}
                              />
                              <label for="no8">No</label>
                            </div>
                            <br></br>
                          </div>
                        )}

                        {item.type === "Yes-No" && item.key === "have_security_checkpoint" && (
                          <div key={"security_checkpoint"} ref={(el) => (refs.current["security_checkpoint"] = el)}>
                            <p>{lnstr === "en" ? item.text_english : item.text_arabi}</p>
                            <div
                              className="custom-radio"
                              style={{
                                color: "#3fd2f2"
                              }}>
                              <input
                                type="radio"
                                id="yes9"
                                name="question9"
                                checked={questionValue?.security_checkpoint=== "Yes"}
                                value="Yes"
                                onChange={(e) => handleQuestionOption(e, "security_checkpoint")}
                              />
                              <label for="yes9">Yes</label>

                              <input
                                type="radio"
                                id="no9"
                                name="question9"
                                checked={questionValue?.security_checkpoint=== "No"}
                                value="No"
                                onChange={(e) => handleQuestionOption(e, "security_checkpoint")}
                              />
                              <label for="no9">No</label>
                            </div>
                            <br></br>
                          </div>
                        )}

                        {item.type === "Text" && item.key === "other_security_system" && (
                          <div className="input-group mb-2 mt-2">
                            <div className="input-group-prepend prepends"></div>
                            <input
                              type="text"
                              placeholder={lnstr === "en" ? item.text_english : item.text_arabi}
                              name="registration"
                              className="form-control"
                              value={questionValue?.other_security_system}
                              onChange={(e) => handleQuestionOption(e, "other_security_system")}
                              required
                            />
                            <br></br>
                          </div>
                        )}
                      </form>
                    </div>
                  ))}
                  <button variant="secondary" className="btn btn-primary rounded-pill text-white my-3 py-1 px-4" onClick={handleSubmit}>
                    {lnstr === "en" ? "Proceed" : "يتابع"}
                  </button>
                </form>
              </div>
            </Col>

            <Col md={3}></Col>
          </div>
        </div>
      </div>

      <Footer />
      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}
    </Fragment>
  );
}

export default Ownerpagedetails;
