import React, { Fragment, useState, useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { useTranslation } from 'react-i18next';
import travelimg from '../tr.png';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';
import { event } from "jquery";
const { config } = require('../api/api');

function ShowHotel() {
    const base_url = config.baseURL;

    const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';


    const { t } = useTranslation();
    const [vendorKey, setVendorkey] = useState('');

    const [formData, setFormData] = useState({
        insurancetype: '',
    });
    const [loading, setLoading] = useState(false);

    const [searchtitle, setSearchTittle] = useState("");
    const [hoteldetails, setHotelDetails] = useState([]);

    const [terminal1, setTerminalvalue1] = useState([]);
    const [terminal2, setTerminalvalue2] = useState([]);
    const [terminal3, setTerminalvalue3] = useState([]);
    const [terminal4, setTerminalvalue4] = useState([]);
    const [terminal5, setTerminalvalue5] = useState([]);
    const [selectedGrossPrice, setSelectedGrossPrice] = useState('USD');
    
    //   const [airport , setAirPorts] =useState([]);

    useEffect(() => {
        setLoading(true)

        const postvData = { organization_name: "Superjet" };
        debugger

        if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {

            debugger
            fetch(base_url + '/create_organization', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(postvData),
            })
                .then(response => response.json())
                .then(data => {
                    setVendorkey(data.result[0].vendor_key);
                })
                .catch(error => {
                    console.error('Error posting data:', error);
                });


        }
    }, [vendorKey]);






    useEffect(() => {
        debugger
        const hotaldetailsjson = sessionStorage.getItem("hotaldetails");
        const details = JSON.parse(hotaldetailsjson);
        setSearchTittle(details.searchtitle)
        const postData = {
            "limit": 20,
            "offset": 0,
            "contract_id": details.contract_id,
            "query": details.query
        };


        if (vendorKey !== '') {
            fetch(base_url + '/get_contract_praducts_iata', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${vendorKey}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(postData),

            })
                .then(response => response.json())
                .then(data => {

                    let terminal1Data = [];
                    let terminal2Data = [];
                    let terminal3Data = [];
                    let terminal4Data = [];
                    let terminal5Data = [];

                    setHotelDetails(data.result.results);

                    const valuedetails = data.result.results;

                    valuedetails.forEach((rowdetails, index) => {
                        if (rowdetails.terminal.title.toLowerCase().includes("terminal 1")) {
                            terminal1Data.push(valuedetails[index]);
                        } else if (rowdetails.terminal.title.toLowerCase().includes("terminal 2")) {
                            terminal2Data.push(valuedetails[index]);
                        } else if (rowdetails.terminal.title.toLowerCase().includes("terminal 3")) {
                            terminal3Data.push(valuedetails[index]);
                        } else if (rowdetails.terminal.title.toLowerCase().includes("terminal 4")) {
                            terminal4Data.push(valuedetails[index]);
                        } else {
                            terminal5Data.push(valuedetails[index]);
                        }
                    });

                    setTerminalvalue1(terminal1Data)
                    setTerminalvalue2(terminal2Data)
                    setTerminalvalue3(terminal3Data)
                    setTerminalvalue4(terminal4Data)
                    setTerminalvalue5(terminal5Data)
                    setLoading(false)
                })
                .catch(error => {
                    console.error('Error posting data:', error);
                    setLoading(false)
                });

        }
    }, [vendorKey]);

    const getGrossPriceForCurrency = (details, selectedCurrency) => {
        debugger
        const currencyData = details.variants[0].adultPrice.currencies.find(currency => currency.currency === selectedCurrency);
        if (currencyData) {

            const splitValue = Math.floor(currencyData.grossPrice)
            return splitValue;
        }
        // If currency not found, return the default gross price (USD or fallback)
        return details.variants[0].adultPrice.grossPrice;
    };
    



    const handleSubmit = (hotaldetails) => {
        setLoading(true)
        debugger
        var postData2 = {
            'fulldetails': hotaldetails,
        }
        sessionStorage.setItem("hotelfulldetails", JSON.stringify(postData2));
        setLoading(false)
        window.location.href = '/lounge-details'
    };








    return (
        <Fragment>

            <div className="header_section1">

                <Navbar />


                <div className="container-fluid bg-breadcrumb">
                    <div className="container text-center py-5">
                        <div>
                            <h3 className="abt-txt my-5">{searchtitle} <h4><strong>{(lnstr === 'en') ? 'List of Lounges' : 'قائمة الصالات\n'}</strong></h4></h3>
                            
                        </div>
                        {/* <h6 className="abt-txt">{(lnstr === 'en') ? 'List of Lounges' : 'قائمة الصالات\n'}</h6> */}
                    </div>
                    {hoteldetails.length > 0 && (
                        <div className="pt-5">

                        <select className="col-md-1 form-control" style={{ borderRadius: '1.5rem' }} onChange={(event)=>{
                            setSelectedGrossPrice(event.target.value)
                        }}>
                            {hoteldetails.map((value, outindex) => {
                                if (outindex === 0) {
                                    return (
                                        <>
                                            <option>
                                                {value.variants[0].adultPrice.baseCurrency}
                                            </option>
                                            {value.variants[0].adultPrice.currencies.map((val, index) => (
                                                <option key={index}>{val.currency}</option>
                                            ))}
                                        </>
                                    );
                                }
                                return null;
                            })}
                        </select>
                            
                        </div>

                    )}
                    
                    


                </div>



            </div>


            <div className="features_section">
                <div className="container">

                    <div className="row">

                        <Col md={1}></Col>

                        <Col md={10} className="myforms my-5">


                        {hoteldetails.length > 0 && (

                            <div>
                                {terminal1.length > 0 && (
                                    <div>
                                    <h3><strong>Terminal 1</strong></h3>
                                    <div class="row">
                                        {terminal1.map((details, index) => (
                                            <div class="col-sm-6 pt-2" key={index}>

                                                <div class="card" style={{ borderRadius: '1.5rem' }} onClick={() => handleSubmit(terminal1[index])}>
                                                    <img src={details.photos[0]} alt="img" style={{ borderTopRightRadius: '1.5rem', borderTopLeftRadius: '1.5rem', height: '300px', objectFit: 'cover' }} />
                                                    <div class="card-body">
                                                        <div className="d-flex justify-content-between">
                                                            <div>
                                                                <h3><strong>{details.title}</strong></h3>
                                                            </div>
                                                            <div>
                                                                <h3><strong> {selectedGrossPrice === 'USD' 
                                                ? details.variants[0].adultPrice.grossPrice 
                                                : getGrossPriceForCurrency(details, selectedGrossPrice)}{selectedGrossPrice}</strong></h3>
                                                            </div>
                                                        </div>
                                                        <p><strong>{details.terminal.title}</strong>
                                                            <p><strong>{details.terminalType[0].charAt(0).toUpperCase() + details.terminalType[0].slice(1)}</strong> | <strong>{details.gateType.charAt(0).toUpperCase() + details.gateType.slice(1)}</strong>
                                                                <p><strong>{details.info.openStatus}</strong></p>
                                                            </p>
                                                        </p>


                                                        {/*   */}
                                                    </div>
                                                </div>
                                            </div>

                                        ))}


                                    </div>

                                </div>
                            )}

                            {terminal2.length > 0 && (
                                <div className="pt-3">
                                    <h3><strong>Terminal 2</strong></h3>
                                    <div class="row">
                                        {terminal2.map((details, index) => (
                                            <div class="col-sm-6 pt-2" key={index}>

                                                <div class="card" style={{ borderRadius: '1.5rem' }} onClick={() => handleSubmit(terminal2[index])}>
                                                    <img src={details.photos[0]} alt="img" style={{ borderTopRightRadius: '1.5rem', borderTopLeftRadius: '1.5rem', height: '300px', objectFit: 'cover' }} />
                                                    <div class="card-body">
                                                        <div className="d-flex justify-content-between">
                                                            <div>
                                                                <h3><strong>{details.title}</strong></h3>
                                                            </div>
                                                            <div>
                                                                <h3><strong>{selectedGrossPrice === 'USD' 
                                                ? details.variants[0].adultPrice.grossPrice 
                                                : getGrossPriceForCurrency(details, selectedGrossPrice)}{selectedGrossPrice}</strong></h3>
                                                            </div>
                                                        </div>
                                                        <p><strong>{details.terminal.title}</strong>
                                                            <p><strong>{details.terminalType[0].charAt(0).toUpperCase() + details.terminalType[0].slice(1)}</strong> | <strong>{details.gateType.charAt(0).toUpperCase() + details.gateType.slice(1)}</strong>
                                                                <p><strong>{details.info.openStatus}</strong></p>
                                                            </p>
                                                        </p>



                                                    </div>
                                                </div>
                                            </div>

                                        ))}


                                    </div>

                                </div>
                            )}


                            {terminal3.length > 0 && (
                                <div className="pt-3">
                                    <h3><strong>Terminal 3</strong></h3>
                                    <div class="row">
                                        {terminal3.map((details, index) => (
                                            <div class="col-sm-6 pt-2" key={index}>

                                                <div class="card" style={{ borderRadius: '1.5rem' }} onClick={() => handleSubmit(terminal3[index])}>
                                                    <img src={details.photos[0]} alt="img" style={{ borderTopRightRadius: '1.5rem', borderTopLeftRadius: '1.5rem', height: '300px', objectFit: 'cover' }} />
                                                    <div class="card-body">
                                                        <div className="d-flex justify-content-between">
                                                            <div>
                                                                <h3><strong>{details.title}</strong></h3>
                                                            </div>
                                                            <div>
                                                                <h3><strong>{selectedGrossPrice === 'USD' 
                                                ? details.variants[0].adultPrice.grossPrice 
                                                : getGrossPriceForCurrency(details, selectedGrossPrice)}{selectedGrossPrice}</strong></h3>
                                                            </div>
                                                        </div>
                                                        <p><strong>{details.terminal.title}</strong>
                                                            <p><strong>{details.terminalType[0].charAt(0).toUpperCase() + details.terminalType[0].slice(1)}</strong> | <strong>{details.gateType.charAt(0).toUpperCase() + details.gateType.slice(1)}</strong>
                                                                <p><strong>{details.info.openStatus}</strong></p>
                                                            </p>
                                                        </p>



                                                    </div>
                                                </div>
                                            </div>

                                        ))}


                                    </div>

                                </div>
                            )}

                            {terminal4.length > 0 && (
                                <div className="pt-3">
                                    <h3><strong>Terminal 4</strong></h3>
                                    <div class="row">
                                        {terminal4.map((details, index) => (
                                            <div class="col-sm-6 pt-2" key={index}>

                                                <div class="card" style={{ borderRadius: '1.5rem' }} onClick={() => handleSubmit(terminal4[index])}>
                                                    <img src={details.photos[0]} alt="img" style={{ borderTopRightRadius: '1.5rem', borderTopLeftRadius: '1.5rem', height: '300px', objectFit: 'cover' }} />
                                                    <div class="card-body">
                                                        <div className="d-flex justify-content-between">
                                                            <div>
                                                                <h3><strong>{details.title}</strong></h3>
                                                            </div>
                                                            <div>
                                                                <h3><strong>{selectedGrossPrice === 'USD' 
                                                ? details.variants[0].adultPrice.grossPrice 
                                                : getGrossPriceForCurrency(details, selectedGrossPrice)}{selectedGrossPrice}</strong></h3>
                                                            </div>
                                                        </div>
                                                        <p><strong>{details.terminal.title}</strong>
                                                            <p><strong>{details.terminalType[0].charAt(0).toUpperCase() + details.terminalType[0].slice(1)}</strong> | <strong>{details.gateType.charAt(0).toUpperCase() + details.gateType.slice(1)}</strong>
                                                                <p><strong>{details.info.openStatus}</strong></p>
                                                            </p>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                        ))}


                                    </div>

                                </div>
                            )}
                            {terminal5.length > 0 && (
                                <div className="pt-3">

                                    <div class="row">
                                        {terminal5.map((details, index) => (

                                            <div class="col-sm-6 pt-2" key={index}>

                                                <h3><strong>{details?.terminal?.title}</strong></h3>

                                                <div class="card" style={{ borderRadius: '1.5rem' }} onClick={() => handleSubmit(terminal5[index])}>
                                                    <img src={details.photos[0]} alt="img" style={{ borderTopRightRadius: '1.5rem', borderTopLeftRadius: '1.5rem', height: '300px', objectFit: 'cover' }} />
                                                    <div class="card-body">
                                                        <div className="d-flex justify-content-between">
                                                            <div>
                                                                <h3><strong>{details.title}</strong></h3>
                                                            </div>
                                                            <div>
                                                                <h3><strong>{selectedGrossPrice === 'USD' 
                                                ? details.variants[0].adultPrice.grossPrice 
                                                : getGrossPriceForCurrency(details, selectedGrossPrice)}{selectedGrossPrice}</strong></h3>
                                                            </div>
                                                        </div>
                                                        <p><strong>{details.terminal.title}</strong>
                                                            <p><strong>{details.terminalType[0].charAt(0).toUpperCase() + details.terminalType[0].slice(1)}</strong> | <strong>{details.gateType.charAt(0).toUpperCase() + details.gateType.slice(1)}</strong>
                                                                <p><strong>{details.info.openStatus}</strong></p>
                                                            </p>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                        ))}


                                    </div>

                                </div>
                            )}
                            </div>

                        )}

                        {/* {hoteldetails.length <= 0 && (
                            <div>
                                <h1 className="text-center">OPPs</h1>
                                <h1 className="text-center">Sorry , Lounges for not available</h1>
                            </div>
                        )} */}

                            




                        </Col>

                        <Col md={1}></Col>

                    </div>

                </div>
            </div>

            <Footer />

            {loading && (
                <div className="overlay">
                    <div className="spinner"></div>
                </div>
            )}

        </Fragment>
    );
}

export default ShowHotel;
