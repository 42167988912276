
import React, { Fragment, useState, useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { useTranslation } from 'react-i18next';
import travelimg from '../tr.png';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';
import SessionRemove from "../helper/sessionremove";
const { config } = require('../api/api');



function HomeInsurance() {
  const base_url = config.baseURL;

  const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';


  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    insurancetype: '',
  });

  const [selectedOption, setSelectedOption] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showSection, setShowSection] = useState(false);

  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setFormData({ ...formData, 'insurancetype': event.target.value });

    if (event.target.value === ' ') {
      setShowSection(true);
      setButtonDisabled(true);
    }
    else {
      setShowSection(false);
      setButtonDisabled(false);
    }
  };

  useEffect(()=>{
     const plan=sessionStorage.getItem("homeplan");
     if(plan){
      const planJson=JSON.parse(plan);
      setSelectedOption(planJson.plan);
     }
  },[]);

  const handleSubmit = async (event) => {

    event.preventDefault();

    if (selectedOption === null) {
      alert('Please choose a plan');
      return false;
    }

    setLoading(true);

    const postvData = { organization_name: "Superjet" };

    try {
      // Create the organization
      const response = await fetch( base_url + '/create_organization', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postvData),
      });

      const data = await response.json();
      const vendorKey = data.result[0].vendor_key;

      // Set vendor key in cookie
      Cookies.set('Vendorkey', vendorKey);

      // Check if the selected option is 'Personal Accident'
      if (selectedOption === 'Owner') {
        const tokenResponse = await fetch( base_url + '/create_home_token', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${vendorKey}`,
          },
        });

        const tokenData = await tokenResponse.json();
        const personaltocken = tokenData.result.data.token;

        const expiredpersonaltocken = new Date();
        expiredpersonaltocken.setTime(expiredpersonaltocken.getTime() + (1 * 60 * 60 * 1000));
        
        Cookies.set('homeData', JSON.stringify(personaltocken), {
          expires: expiredpersonaltocken,
        });

        var plandata= {
          'plan':selectedOption
        }

        sessionStorage.clear();
        sessionStorage.setItem("homeplan" , JSON.stringify(plandata));


        
        // Redirect after token is set*/}
        
        window.location.href = '/home-ownerpage';
    
      } 
      else {
        
        const tokenResponse = await fetch( base_url + '/create_home_token', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${vendorKey}`,
          },
        });

        const tokenData = await tokenResponse.json();
        const personaltocken = tokenData.result.data.token;

        const expiredpersonaltocken = new Date();
        expiredpersonaltocken.setTime(expiredpersonaltocken.getTime() + (1 * 60 * 60 * 1000));

        Cookies.set('homeData', JSON.stringify(personaltocken), {
          expires: expiredpersonaltocken,
        });


        var plandata= {
          'plan':selectedOption
        }
        sessionStorage.clear();

        sessionStorage.setItem("homeplan" , JSON.stringify(plandata));
        
        // SessionRemove();

        window.location.href = '/home-tenant';
        
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false); // Ensure loading state is reset
    }
  };


  return (
    <Fragment>

      <div className="header_section1">

        <Navbar />


        <div className="container-fluid bg-breadcrumb">
          <div className="container text-center py-5">
            <h3 className="abt-txt my-5">{(lnstr === 'en') ? 'Home Insurance' : 'التأمين على المنزل\n'}</h3>
          </div>
        </div>


      </div>


      <div className="features_section">
        <div className="container">

          <div className="row">

            <Col md={3}></Col>

            <Col md={6} className="myforms my-5">



              <h4 className="px-0 py-2">{(lnstr === 'en') ? 'Choose your cover plan' : 'اختر خطة التغطية الخاصة بك\n'}</h4>

              <form onSubmit={handleSubmit}>

                <div class="align-items-center travel-selection-option">
                  {(lnstr === 'en') ? 'Owner' : 'مالك\n'}
                  <input type="radio" name="homeinsurance" value="Owner" 
                  checked={selectedOption==="Owner"}
                  className="d-inline-block option-circle ml-auto" onChange={handleOptionChange}  />
                </div>

                <div class="align-items-center travel-selection-option my-3">
                  {(lnstr === 'en') ? 'Tenant' : 'مستاجر\n'}
                  <input type="radio" name="homeinsurance" value="Tenant"
                  checked={selectedOption==="Tenant"}
                   className="d-inline-block option-circle ml-auto" onChange={handleOptionChange} />
                </div>



                <button type="submit" disabled={buttonDisabled} className="btn btn-primary rounded-pill text-white my-1 py-1 px-4">{t('Submit')}</button>

              </form>


            </Col>

            <Col md={3}></Col>

          </div>

        </div>
      </div>

      <Footer />

      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}

    </Fragment>
  );
}

export default HomeInsurance;
